:root {
  --bg_color: #000000;
  --second_bg: #171717;
  --main_blue: #0ae7f8;
  --btn_border: #0ae7f8;
  --second_blue: #1b31dd;
  --txt_color: #FFF;
  --main_font: 'Gotham Medium';
  --btn_font: "Monda Regular";
  --btn_radius: 5px;
  --btn_bg: rgba(10, 232, 248, 0.2);
  --second_btn_bg: #0ae7f8;
  --second_btn_border: #0ae7f8;
  --second_btn_txt_color: #000;
}

@font-face {
  font-family: "Gotham Book";
  src: url("./assets/fonts/Gotham-Book.ttf") format("truetype");
}

@font-face {
  font-family: "Gotham Medium";
  src: url("./assets/fonts/Gotham-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Monda Regular";
  src: url("./assets/fonts/Monda-Regular.ttf") format("truetype");
}

body {
  padding: 20px;
  background-color: var(--bg_color);
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#modal {
  position: absolute;
  z-index: 5;
}

html {
  overflow-x: hidden;
}

.justify-center {
  display: flex;
  justify-content: center;
}

.justify-end {
  display: flex;
  justify-content: end;
}

.pl-20 {
  margin-left: 20px;
}

.pl-40 {
  margin-left: 40px;
}

.pt-10 {
  padding-top: 10px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-40 {
  padding-top: 40px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-30 {
  padding-bottom: 30px;
}

.mr-20 {
  margin-right: 20px;
}

.h-100 {
  height: 100%;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0;
}

.lm-input {
  position: relative;
  display: block;
  width: 80%;
  margin: 0 auto;
  font-family: "Gotham Book";
  font-size: 10pt;
  color: #ffffff;
  border: 1px solid var(--main_blue);
  border-radius: 5px;
  background-color: #000000;
}

.align-center {
  display: flex;
  align-items: center;
}

.grid-center {
  display: grid;
  align-content: center;
}

.grid-end {
  display: grid;
  justify-content: end;
}

p {
  font-family: "Gotham Book";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 19px;
  margin: 0 5px;
}
.white {
  color: #ffffff;
}
.red {
  color: #ff0000;
}
/* WIDTHES */
.w-10 {
  width: 10%;
}
.w-15 {
  width: 15%;
}
.w-20 {
  width: 20%;
}
.w-30 {
  width: 30%;
}
.w-40 {
  width: 40%;
}
.w-60 {
  width: 60%;
}
.w-70 {
  width: 70%;
}
.w-80 {
  width: 80%;
}
.w-85 {
  width: 85%;
}
.w-90 {
  width: 90%;
}

.blue {
  color: var(--main_blue);
}

.red {
  color: red;
}

.green {
  color: rgb(0, 221, 0);
}

.blue-title {
  color: var(--main_blue);
  font-family: "Gotham Book";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;

  &.big_size {
    font-size: 17px;
    font-weight: bold;
  }
}

.center {
  display: flex;
  justify-content: center;
}

.end {
  display: flex;
  justify-content: flex-end;
}

.main-content {
  width: 90%;
}

/* .box {
  border: 1px solid var(--main_blue);
  border-radius: 5px;
  font-family: "Gotham Medium";
  padding: 20px;
  color: #ffffff;
  background-color: #171717;
  box-shadow: -3px 3px 4px 0px #0ae8f887;
} */

.box {
  font-family: var(--main_font);
  color: var(--txt_color);

  &.main_border {
    border: 1px solid var(--main_blue);
    padding: 20px;
    border-radius: 5px;
    background-color: var(--second_bg);
  }
}

.dark-box {
  border: 1px solid var(--main_blue);
  border-radius: 5px;
  font-family: "Gotham Medium";
  padding: 20px;
  color: #ffffff;
  background-color: #000000;
}

.blue-dark-box {
  border: 1px solid var(--second_blue);
  border-radius: 5px;
  font-family: "Gotham Medium";
  padding: 20px;
  color: #ffffff;
  background-color: #171717;
}

.bigbox {
  margin-top: 24px;
}

.link {
  display: inline-block;
  color: var(--main_blue);
  text-decoration: none;

  &.white {
    color: white;
    text-decoration: underline;
  }
}

hr {
  opacity: 1 !important;
}

.capitalize {
  text-transform: capitalize;
}

.chain-logo-details {
  padding-left: 10px;
  padding-right: 5px;
}

@media (max-width: 991px) {
  .box {
    margin-top: 20px;
  }
}

@media (max-width: 900px) {
  .main-content {
    width: 100%;
  }
}
